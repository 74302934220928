import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DigitalsaksharService {


  constructor(private http: HttpClient) {}

  FillDropDown(tablename,column1,column2,where){
    return this.http.get(environment.apiUrl+"JankariPortal/" + "FillDropDownCampaign?tablename="+btoa(tablename)+"&column1="+btoa(column1)+"&column2="+btoa(column2)+"&whr="+ btoa(where)  )
  }

  GetDSEmployeeCoursewiseBeniCountonepage(state:string){
    return this.http.get(environment.apiUrl+"Employee/" + "GetDSEmployeeCoursewiseBeniCountonepage/" + state  )
  }
  GetDSEmployeeAgeGroupBeneficiarygraphonepage(state:string){
    return this.http.get(environment.apiUrl+"Employee/" + "GetDSEmployeeAgeGroupBeneficiarygraphonepage/" + state  )
  }
  GetDSEmployeeFeedbackgraphonepage(state:string){
    return this.http.get(environment.apiUrl+"Employee/" + "GetDSEmployeeFeedbackgraphonepage/" + state  )
  }
  GetRptDSAssessmentPieGraphonepage(state:string){
    return this.http.get(environment.apiUrl+"Employee/" + "GetRptDSAssessmentPieGraphonepage/" + state  )
  }

  GetDSEmployeevsvolenteercountonepage(where){
    return this.http.get(environment.apiUrl+"Employee/" + "GetDSEmployeevsvolenteercountonepage?whr=" + btoa(where)  )
  }

 
}
