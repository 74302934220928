import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import * as Rellax from 'rellax';
import { DigitalsaksharService } from 'app/digitalsakshar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Chart } from 'chart.js';
import { Dsfeedbackgraph } from 'app/dsfeedbackgraph';

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    }
    `]
})

export class ComponentsComponent implements OnInit, OnDestroy {
  //  start========================================================================
  batch;
  // bargraph
  public barChartType: string;
  chartOptions = {
    responsive: true
  }

  pm1: number;
  pm2: number;
  pm3: number;
  pm4: number;
  pm5: number;
  pm6: number;
  pm7: number;
  pf1: number;
  pf2: number;
  pf3: number;
  pf4: number;
  pf5: number;
  pf6: number;
  pf7: number;

  pm11: number;
  pm21: number;
  pm31: number;
  pm41: number;
  pm51: number;
  pm61: number;
  pm71: number;
  pf11: number;
  pf21: number;
  pf31: number;
  pf41: number;
  pf51: number;
  pf61: number;
  pf71: number;


  labels = ['PrePrimary', 'SecondaryComputerScience', 'PrimaryFunWithEnglish', 'DigitalSkilsForYouth', 'DigitalSkillForParents', 'SoftSkill', 'DigitalEnhancementIDI'];


  chartData = [
    {
      label: '',
      data: [],

    }
  ];

  colors = [
    {
      backgroundColor: 'rgba(53, 125, 184,0.9)'
    },
    {
      backgroundColor: 'rgba(245, 82, 73,0.9)'
    }
  ]
  //barchart
  value;
  tb1 = 0; tb2 = 0; tb3 = 0; tb4 = 0;
  Feedbackgraph;
  excellent = 0;
  good = 0;
  fair = 0;
  recommend = 0;

  dataobj;


  // attendace bar ghraph
  empname = [];
  totwhr = [];
  totot = [];
  chart: any;
  dept = [];
  dccname = [];
  totbencount = [];
  totcountt = [];
  depid
  // end of bargraph

  public pieChartLabels2: Array<string> = ['90+ Scrore(%)', '70-90 Score(%)', '50-70 Score (%)'];
  public pieChartType2: string;

  pieChartColor2: any = [
    {
      backgroundColor: ['rgba(76, 168, 50, 1)',
        'rgba(245, 169, 17,1)',
        'rgba(240, 233, 41,1)'
      ]
    }
  ]
  public piechartOptions2: any = {
    responsive: true,

  };
  public pieChartLabels1: Array<string> = ['Excellent', 'Good', 'Fair'];
  public pieChartType1: string;

  pieChartColor1: any = [
    {
      backgroundColor: ['rgba(76, 168, 50, 1)',
        'rgba(245, 169, 17,1)',
        'rgba(240, 233, 41,1)'
      ]
    }
  ]
  public piechartOptions1: any = {
    responsive: true,

  };
  data: Date = new Date();
  page = 4;
  page1 = 5;
  page2 = 3;
  focus;
  focus1;
  focus2;

  date: { year: number, month: number };

  public isCollapsed = true;
  public isCollapsed1 = true;
  public isCollapsed2 = true;

  state_icon_primary = true;
  state;
  column1;
  malecount;
  femalecount;
  statecount;
  enrollcount;
  Enrollement;
  enviro;
  Female;
  male;
  female;
  statefill;
  DSAnalysisCount;
  beniCount = [];
  coursename = [];

  array = [];
  StrWhere = '';

  loading = false;
  public myChart: Chart;
  public myyyChart: Chart;
  public pieChartData: any[];
  public pieChartData1: any[];
  public pieChartData2: any[];
  public pieChartLabels: Array<string> = ['Pre-Primary (Age 3 to 5)', 'Primary (Age 5+ to 11)', 'Upper Primary/Scondary (Age 11+ to 18)', 'Youth (Age 18+ to 50)'];
  public pieChartType: string;
  piep1: any;
  piep2: any;
  piep3: any;
  piep4: any;
  piep5: any;
  pietot: any;
  pieChartColor: any = [
    {
      backgroundColor: ['rgba(3, 65, 158,1.0)',
        'rgba(212, 190, 0,1.0)',
        'rgba(99, 199, 0,1.0)',
        'rgba(247, 65, 45,1.0)',
        'rgba(3, 161, 145,1.0)',

      ]
    }
  ]
  public piechartOptions: any = {
    responsive: true,

  };
  // end=================================================================================

  constructor(private service: DigitalsaksharService, private router: Router, private route: ActivatedRoute) {

    this.pageload();
  }

  pageload() {
    this.service.FillDropDown("DigitalEmployee", "CAST(count(distinct DigitalEmployee.city) AS varchar)", " CAST(count(distinct DigitalEmployee.city) AS varchar)", "left join City on DigitalEmployee.city=City.ctname left join District on District.dtname=City.dtname left join State on State.stname=District.stname ").subscribe((data: any) => {
      this.state = data;
     
      var b = data[0];
      this.column1 = b.column1;

    });


    // this.service.FillDropDown("DigitalEmployee", "CAST(count(distinct DigitalEmployee.dsempid) AS varchar)", "CAST(count(distinct DigitalEmployee.dsempid) AS varchar)", "where createby is not null").subscribe((data: any) => {
    //   this.Enrollement = data;
    //   var b = data[0];
    //   this.enviro = b.column1;

    // });

    // this.service.FillDropDown("DigitalEmployee", "CAST(count(distinct DigitalEmployee.dsempid) AS varchar)", "CAST(count(distinct DigitalEmployee.dsempid) AS varchar)", "where createby is not null").subscribe((data: any) => {
    //   this.Enrollement = data;
    //   var b = data[0];
    //   this.enviro = b.column1;
    //   console.log(this.enviro);
      

    // });
    this.enviro = '743037';

    // this.service.FillDropDown("(select (CEILing(CAST(cast((select count(distinct DigitalEmployee.dsempid)from DigitalEmployee left join City on DigitalEmployee.city=City.ctname left join District on District.dtname=City.dtname left join State on State.stname=District.stname where gender='Female' )as decimal)as decimal(10,0))))q1,cast(count(distinct DigitalEmployee.dsempid)as decimal)q2", "CAST(CAST(( sum(q1)*100/sum(q2))as decimal(10,0))AS varchar)", " CAST(CAST(( sum(q1)*100/sum(q2))as decimal(10,0))AS varchar)", "from DigitalEmployee  left join City on DigitalEmployee.city=City.ctname left join District on District.dtname=City.dtname left join State on State.stname=District.stname )temp ").subscribe((data: any) => {
    //   this.Female = data;
    //   console.log(data);
      
    //   var b = data[0];
    //   this.female = b.column1;

    // });
    
    this.service.FillDropDown("DigitalEmployee", "'49'", "CAST(count(distinct DigitalEmployee.dsempid) AS varchar)", "where createby is not null").subscribe((data: any) => {
      this.Female = data;
      console.log(data);
      
      var b = data[0];
      this.female = b.column1;

    });

    // this.service.FillDropDown("(select (CEILing(CAST(cast((select count(distinct DigitalEmployee.dsempid)from DigitalEmployee left join City on DigitalEmployee.city=City.ctname left join District on District.dtname=City.dtname left join State on State.stname=District.stname where gender='Male' )as decimal)as decimal(10,0))))q1,cast(count(distinct DigitalEmployee.dsempid)as decimal)q2", "CAST(CAST(( sum(q1)*100/sum(q2))as decimal(10,0))AS varchar)", " CAST(CAST(( sum(q1)*100/sum(q2))as decimal(10,0))AS varchar)", "from DigitalEmployee  left join City on DigitalEmployee.city=City.ctname left join District on District.dtname=City.dtname left join State on State.stname=District.stname )temp ").subscribe((data: any) => {
    //   this.male = data;
    //   console.log(data);

    //   var b = data[0];
    //   this.male = b.column1;

    // });

    this.service.FillDropDown("DigitalEmployee", "'51'", "CAST(count(distinct DigitalEmployee.dsempid) AS varchar)", "where createby is not null").subscribe((data: any) => {
      this.male = data;
      console.log(data);

      var b = data[0];
      this.male = b.column1;

    });
    this.service.GetDSEmployeeCoursewiseBeniCountonepage(null)

    .subscribe((res: any) => {

      this.coursename = new Array();
      this.beniCount = new Array();

      var totDigitalSkillForParents = 0, totDigitalSkillForYouth = 0, totPrePrimary = 0, totPrimaryFunWitEnglish = 0,
        totSecondaryComputerScience = 0, totSoftSkill = 0, totDigitalEnhancementIDI = 0;

      res.forEach(
        y => {
          [

            totDigitalSkillForParents += (y.DigitalSkillForParents),
            totDigitalSkillForYouth += (y.DigitalSkillForYouth),
            totPrePrimary += (y.PrePrimary),
            totPrimaryFunWitEnglish += (y.PrimaryFunWitEnglish),
            totSecondaryComputerScience += (y.SecondaryComputerScience),
            totSoftSkill += (y.SoftSkill),
            totDigitalEnhancementIDI += (y.DigitalEnhancementIDI),

          ];
        });

        this.beniCount.push(totPrePrimary);
        this.coursename.push('Pre Primary');
        this.beniCount.push(totPrimaryFunWitEnglish);
        this.coursename.push('Primary Fun With English');
        this.beniCount.push(totSecondaryComputerScience);
        this.coursename.push('Secondary Computer Science');
        this.beniCount.push(totSoftSkill);
        this.coursename.push('Soft Skill');
        this.beniCount.push(totDigitalSkillForYouth);
        this.coursename.push('Digital Skill For Youth'); 
        this.beniCount.push(totDigitalSkillForParents);
        this.coursename.push('Digital Skill For Parents');
        this.beniCount.push(totDigitalEnhancementIDI);
        this.coursename.push('Digital Enhancement IDI');

      if (this.myChart) this.myChart.destroy();
      this.myChart = new Chart('canvass', {

        type: 'bar',
        data: {
          labels: this.coursename,
          datasets: [
            {
              label: 'Course Name',
              data: this.beniCount,
              backgroundColor: "#ffc107",
              borderColor: '#ffc107',
              fill: true,

            }
          ],

        },

        options: {
          legend: {
            display: true,
            position: 'bottom',
            // legendname: 'Course Name',

          },

          scales: {
            xAxes: [{

              display: true,
              stacked: true,
              distribution: 'series',
            }],
            yAxes: [{
              display: true,
              stacked: true,
              ticks: {
                beginAtZero: true,



              },
              scaleLabel: {
                display: true,
                labelString: "Count",


              }
            }],
          }
        }
      });


    });
  this.service.GetDSEmployeeAgeGroupBeneficiarygraphonepage(null)
    .subscribe((res: any) => {
      
      this.pieChartData = Array();
      this.pieChartData = [];

      this.piep1 = 0;
      this.piep2 = 0;
      this.piep3 = 0;
      this.piep4 = 0;
      // this.piep5 = 0;
      this.pietot = 0;
      for (let i = 0; i < res.length; i++) {

        var p45total= res[i].p4+res[i].p5;
        this.piep1 += res[i].p1;
        this.piep2 += res[i].p2;
        this.piep3 += res[i].p3;
        this.piep4 += p45total ;
        // this.piep5 += res[i].p5;

      }


      this.pietot = this.piep1 + this.piep2 + this.piep3 + this.piep4;// + this.piep5;

      this.pieChartData.push(this.piep1,
        this.piep2,
        this.piep3,
        this.piep4
        // ,
        // this.piep5
        );



    });
  this.service.GetRptDSAssessmentPieGraphonepage(null)
    .subscribe((res: any) => {
      this.pieChartData2 = [
        ((res[0].agrade * 100) / (res[0].agrade + res[0].bgrade + res[0].cgrade)).toFixed(2)
        , ((res[0].bgrade * 100) / (res[0].agrade + res[0].bgrade + res[0].cgrade)).toFixed(2)
        , ((res[0].cgrade * 100) / (res[0].agrade + res[0].bgrade + res[0].cgrade)).toFixed(2)];



    })

  this.service.GetDSEmployeeFeedbackgraphonepage(null)
    .subscribe((res: any) => {

      this.pieChartData1 = [
        ((res[0].Excellent * 100) / (res[0].Excellent + res[0].Good + res[0].Fair)).toFixed(2)
        , ((res[0].Good * 100) / (res[0].Excellent + res[0].Good + res[0].Fair)).toFixed(2)
        , ((res[0].Fair * 100) / (res[0].Excellent + res[0].Good + res[0].Fair)).toFixed(2)];


    })
  }

  ngOnInit() {
    this.loading = true;
    this.pieChartType = 'pie';
    this.pieChartType1 = 'pie';
    this.pieChartType2 = 'pie';
    this.service.FillDropDown("EnrollmentCountGraph", "distinct state", "state", "where type='Onepage' and  State not in ('UAE','USA','Sierra Leone','Al Qassim','Germany','Nepal','UK','Balliya') order by  State ").subscribe((data: any) => {
      this.statefill = data;
      this.loading = false;
    });

   


  }
  ngOnDestroy() {
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('index-page');
  }


  filterrecord(searchstatefill) {
    this.loading = false;
    this.array = [];
    let where = '';
    this.StrWhere = '';
if(searchstatefill=='Select' || searchstatefill==undefined || searchstatefill=='0: undefined' )
{
 this.pageload();
}
else
{
    this.service.FillDropDown("DigitalEmployee", "CAST(count(distinct DigitalEmployee.city) AS varchar)", " CAST(count(distinct DigitalEmployee.city) AS varchar)", "left join City on DigitalEmployee.city=City.ctname left join District on District.dtname=City.dtname left join State on State.stname=District.stname  where State.stname=  '" + searchstatefill + "'  ").subscribe((data: any) => {
      this.state = data;
     
      var b = data[0];
      this.column1 = b.column1;
    });

    this.service.FillDropDown("DigitalEmployee", "CAST(count(distinct DigitalEmployee.dsempid) AS varchar)", "CAST(count(distinct DigitalEmployee.dsempid) AS varchar)", "left join City on DigitalEmployee.city=City.ctname left join District on District.dtname=City.dtname left join State on State.stname=District.stname  where createby is not null   and  State.stname='" + searchstatefill + "' ").subscribe((data: any) => {
      this.Enrollement = data;
      var b = data[0];
      this.enviro = b.column1;
    });

    this.service.FillDropDown("(select (CEILing(CAST(cast((select count(distinct DigitalEmployee.dsempid)from DigitalEmployee left join City on DigitalEmployee.city=City.ctname left join District on District.dtname=City.dtname left join State on State.stname=District.stname where gender='Female' and State.stname='" + searchstatefill + "'  )as decimal)as decimal(10,0))))q1,cast(count(distinct DigitalEmployee.dsempid)as decimal)q2", "CAST(CAST(( sum(q1)*100/sum(q2))as decimal(10,0))AS varchar)", " CAST(CAST(( sum(q1)*100/sum(q2))as decimal(10,0))AS varchar)", "from DigitalEmployee  left join City on DigitalEmployee.city=City.ctname left join District on District.dtname=City.dtname left join State on State.stname=District.stname where State.stname='" + searchstatefill + "'  )temp ").subscribe((data: any) => {

      this.female = data;
      var b = data[0];
      this.female = Number(b.column1);
    })

    this.service.FillDropDown("(select (CEILing(CAST(cast((select count(distinct DigitalEmployee.dsempid)from DigitalEmployee left join City on DigitalEmployee.city=City.ctname left join District on District.dtname=City.dtname left join State on State.stname=District.stname where gender='Male' and State.stname='" + searchstatefill + "'  )as decimal)as decimal(10,0))))q1,cast(count(distinct DigitalEmployee.dsempid)as decimal)q2", "CAST(CAST(( sum(q1)*100/sum(q2))as decimal(10,0))AS varchar)", " CAST(CAST(( sum(q1)*100/sum(q2))as decimal(10,0))AS varchar)", "from DigitalEmployee  left join City on DigitalEmployee.city=City.ctname left join District on District.dtname=City.dtname left join State on State.stname=District.stname where State.stname='" + searchstatefill + "'  )temp ").subscribe((data: any) => {
      this.male = data;
      var b = data[0];
      this.male = Number(b.column1);

    });
    if (searchstatefill != null && searchstatefill !== '' && searchstatefill !== 'undefined' && searchstatefill !== 'Select') {
      this.array.push("%23data.state='" + searchstatefill + "'");

    }

    if (this.array.length === 0) {
      where = this.StrWhere;
    } else if (this.array.length === 1) {
      this.StrWhere = ' where ' + this.array[0].toString();
    } else {
      this.StrWhere = ' where ' + this.array[0].toString();
      for (let i = 1; i < this.array.length; i++) {
        this.StrWhere = this.StrWhere + ' and ' + this.array[i].toString();
      }


    }
    where = this.StrWhere;


    console.log("where" + where);


    this.service.GetDSEmployeeCoursewiseBeniCountonepage(searchstatefill)

      .subscribe((res: any) => {

        this.coursename = new Array();
        this.beniCount = new Array();

        var totDigitalSkillForParents = 0, totDigitalSkillForYouth = 0, totPrePrimary = 0, totPrimaryFunWitEnglish = 0,
          totSecondaryComputerScience = 0, totSoftSkill = 0, totDigitalEnhancementIDI = 0;

        res.forEach(
          y => {
            [

              totDigitalSkillForParents += (y.DigitalSkillForParents),
              totDigitalSkillForYouth += (y.DigitalSkillForYouth),
              totPrePrimary += (y.PrePrimary),
              totPrimaryFunWitEnglish += (y.PrimaryFunWitEnglish),
              totSecondaryComputerScience += (y.SecondaryComputerScience),
              totSoftSkill += (y.SoftSkill),
              totDigitalEnhancementIDI += (y.DigitalEnhancementIDI),

            ];
          });

        this.beniCount.push(totPrePrimary);
        this.coursename.push('Pre Primary');
        this.beniCount.push(totPrimaryFunWitEnglish);
        this.coursename.push('Primary Fun With English');
        this.beniCount.push(totSecondaryComputerScience);
        this.coursename.push('Secondary Computer Science');
        this.beniCount.push(totSoftSkill);
        this.coursename.push('Soft Skill');
        this.beniCount.push(totDigitalSkillForParents);        
        this.coursename.push('Digital Skill For Parents');
        this.beniCount.push(totDigitalSkillForYouth);
        this.coursename.push('Digital Skill For Youth');
        this.beniCount.push(totDigitalEnhancementIDI);
        this.coursename.push('Digital Enhancement IDI');

        if (this.myChart) this.myChart.destroy();
        this.myChart = new Chart('canvass', {

          type: 'bar',
          data: {
            labels: this.coursename,
            datasets: [
              {
                label: 'Course Name',
                data: this.beniCount,
                backgroundColor: "#ffc107",
                borderColor: '#ffc107',
                fill: true,

              }
            ],

          },

          options: {
            legend: {
              display: true,
              position: 'bottom',
              // legendname: 'Course Name',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                ticks: {
                  beginAtZero: true,



                },
                scaleLabel: {
                  display: true,
                  labelString: "Count",


                }
              }],
            }
          }
        });
      });

    this.service.GetDSEmployeeAgeGroupBeneficiarygraphonepage(searchstatefill)
      .subscribe((res: any) => {

        this.pieChartData = Array();
        this.pieChartData = [];

        this.piep1 = 0;
        this.piep2 = 0;
        this.piep3 = 0;
        this.piep4 = 0;
         this.piep5 = 0;
        this.pietot = 0;
        for (let i = 0; i < res.length; i++) {


          this.piep1 += res[i].p1;
          this.piep2 += res[i].p2;
          this.piep3 += res[i].p3;
          this.piep4 += res[i].p4;
           this.piep5 += res[i].p5;

        }


        this.pietot = this.piep1 + this.piep2 + this.piep3 + this.piep4 + this.piep5;

        this.pieChartData.push(this.piep1,
          this.piep2,
          this.piep3,
          this.piep4,
           this.piep5
          );

      });
    this.service.GetDSEmployeeFeedbackgraphonepage(searchstatefill)
      .subscribe((res: any) => {

        this.pieChartData1 = [
          ((res[0].Excellent * 100) / (res[0].Excellent + res[0].Good + res[0].Fair)).toFixed(2)
          , ((res[0].Good * 100) / (res[0].Excellent + res[0].Good + res[0].Fair)).toFixed(2)
          , ((res[0].Fair * 100) / (res[0].Excellent + res[0].Good + res[0].Fair)).toFixed(2)];


      })


    this.service.GetRptDSAssessmentPieGraphonepage(searchstatefill)
      .subscribe((res: any) => {
        this.pieChartData2 = [
          ((res[0].agrade * 100) / (res[0].agrade + res[0].bgrade + res[0].cgrade)).toFixed(2)
          , ((res[0].bgrade * 100) / (res[0].agrade + res[0].bgrade + res[0].cgrade)).toFixed(2)
          , ((res[0].cgrade * 100) / (res[0].agrade + res[0].bgrade + res[0].cgrade)).toFixed(2)];
      })
  }}

}
